import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'show-document',
  templateUrl: 'show-document.modal.html'
})

export class ShowDocumentModal implements OnInit {

  @Input() document_url: string;
  @Input() document_name: string;
  @Input() base64file: string;
  @Input() showFullscreen: boolean = true;
  @Input() fileType: string;
  iframeHeight: string;
  pdfData: any;
  safeUrl: SafeUrl;

  constructor( private viewCtrl: ModalController,
               public sanitization: DomSanitizer ) {
    console.log(this)
  }

  ngOnInit() {
    if (this.document_url) {
      this.safeUrl = this.sanitization.bypassSecurityTrustResourceUrl(this.document_url)
    }
  }

  dismiss(showFullscreen = false) {
    this.viewCtrl.dismiss({
      showFullscreen
    })
  }

  ionViewDidEnter(): void {
    setTimeout(() => {
      const iFrame = document.getElementById( 'iframeContainer' );
      if (iFrame.getClientRects().length) {
        this.iframeHeight = `${iFrame.getClientRects()[0].height}px`
      }
    }, 250)
  }
}
